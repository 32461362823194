import styled from "styled-components";

export const LoadingGIFContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;


export const LoadingGIFWrapper = styled.div`
  box-sizing: border-box;

  position: absolute;
  width: 628px;
  height: 420px;

  text-align: center;

  z-index: 2;
  animation: modaldown 0.25s linear;

  @keyframes modaldown {
    from {
      transform: translateY(-5%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

export const LoadingGIF = styled.img`
  width: 300px;
  height: 300px;
`

import React from 'react'
import {
    LoadingGIFContainer,
    LoadingGIFWrapper,
    LoadingGIF
}from "./LoadingElements"
import LoadingGIFURL from "../../videos/LoadingAnimation.gif"

const LoadingAnimation = (props) => {
    const size = props.size
    console.log(size)
  return (
    <LoadingGIFContainer>
        <LoadingGIFWrapper>
            <LoadingGIF src = {LoadingGIFURL}/>
        </LoadingGIFWrapper>
  </LoadingGIFContainer>
  )
}

export default LoadingAnimation